export default {
	data() {
		return {
			loading: true,
			getFeedbackManage: {
				page: 1,
				size: 10,
				searchDates: [],
				startCreateTime: null,
				endCreateTime: null,
				userAccount:'',
				status: null
			},
			tableData: [],
			total: 0
		}
	},
	created() {
		this.getFeedbackManageList();
	},
	methods: {
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getFeedbackManage.size = newSize;
			this.getFeedbackManageList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getFeedbackManage.page = newPage;
			this.getFeedbackManageList()
		},
		doGetFeedbackManageSearch() {
			this.tableData.splice(0, this.tableData.length);			
			this.getFeedbackManage.page = 1;
			if (this.getFeedbackManage.searchDates !== null && this.getFeedbackManage.searchDates.length >
				1) {
				this.getFeedbackManage.startCreateTime = this.getFeedbackManage.searchDates[0] + ' 00:00:00';
				this.getFeedbackManage.endCreateTime = this.getFeedbackManage.searchDates[1] + ' 23:59:59';
			} else {
				this.getFeedbackManage.startCreateTime = '';
				this.getFeedbackManage.endCreateTime = '';
			}
			this.getFeedbackManageList();
		},
		/*获取版本列表数据*/
		getFeedbackManageList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			console.log(this.getFeedbackManage)
			this.$axios({
				method: "post",
				url: "/api/cgm/web/patientFeedback/page", // 接口地址
				data: this.getFeedbackManage
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					for(var i=0;i<this.tableData.length;i++){
						var mobile = this.tableData[i].userMobile;
						if(mobile){
							mobile = mobile.substring(0,3) + "****" + mobile.substring(7,12);
							this.tableData[i].userMobile= mobile;
						}
					}
					console.log(this.tableData)
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					this.loading = false;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		//编辑版本
		redactButton(row) {
			this.$router.push({
				path: 'feedback-edit',
				query: {
					feedbackUedact: row,
				}
			});
		}
	},
}
